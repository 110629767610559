import React, { memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Home from './components/routes/Home';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import useHeartbeat from './hooks/useHeartbeat';
import Debug from './components/Debug';
import { useAppDispatch } from './redux/config/store';
import { authPlayfab } from './redux/playfab';
import { guid } from './utils/guid';

function App() {
	const {
		playerId,
	} = usePlayFab();

	useRealtime();
	useInventoryExpiration();
	useHeartbeat();

	const dispatch = useAppDispatch();
	useEffect(() => {
		if (!playerId) {
			const id = localStorage.getItem('PlayerCustomID') || guid();
			localStorage.setItem('PlayerCustomID', id);
			dispatch(authPlayfab({ userId: id }));
		}
	}, [playerId]);

	return (
		<>
			<Debug />
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={<Home />} />
				</Route>
			</Routes>
		</>
	);
}

export default memo(App);
