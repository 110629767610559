import React, { useEffect, useRef, useState } from 'react';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { lerp } from '../../utils/lerp';

const MAX_VOLUME = 0.5;

export default function AudioPlayer({ file, autoPlay = false, loop = false }:{ file:string, autoPlay?:boolean, loop?:boolean }) {

	const audioRef = useRef<HTMLAudioElement>(null);
	const [shouldTryOnFocus, setShouldTryOnFocus] = useState(false);
	const [muted, setMuted] = useState(true);

	useEffect(() => {
		if (file && autoPlay && audioRef.current?.paused) {
			audioRef.current?.play().then(() => setMuted(false)).catch(() => {
				audioRef.current.volume = 0;
				setShouldTryOnFocus(true);
			});
		}
	}, [autoPlay, file]);

	useEffect(() => {
		const fn = () => {
			if (audioRef.current?.paused) {
				audioRef.current?.play().then(() => setMuted(false)).catch(() => {
					console.error('Audio play failed');
				});
			}
		};

		if (shouldTryOnFocus) {
			document.documentElement.addEventListener('click', fn);

			return () => {
				document.documentElement.removeEventListener('click', fn);
			};
		}

		return () => {};
	}, [shouldTryOnFocus]);

	function onClickMute() {
		setMuted(m => !m);
	}

	useAnimationFrame(() => {
		if (audioRef.current) {
			audioRef.current.volume = lerp(audioRef.current.volume, muted ? 0 : MAX_VOLUME, 0.025);
		}
	});

	return (
		<div className="audio-player">
			<audio ref={audioRef} src={file} loop={loop}></audio>
			<button onClick={onClickMute} className={muted ? '' : 'paused'}>{muted ? 'unmute' : 'mute'}</button>
		</div>
	);
}