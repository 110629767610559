import { useEffect } from 'react';
import { POLL_RATES } from '../Constants';
import { useAppDispatch } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import usePlayer from './usePlayer';

export default function useHeartbeat() {
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		let interval;

		if (playerId) {
			interval = setInterval(() => {
				dispatch(writePlayerEvent({
					name: 'heartbeat',
					body: {},
				}));
			}, POLL_RATES.SEND_HEARTBEAT);
		}

		return () => {
			clearInterval(interval);
		};
	}, [playerId]);
}