/* eslint-disable space-before-function-paren */
import { useRef, useState, useCallback, useEffect } from 'react';
import { useAppDispatch } from '../redux/config/store';
import { updateDisplayName, writePlayerEvent } from '../redux/playfab';
import useGlobalVariables from './useGlobalVariables';
import useOpenAIChat from './useOpenAiChat';
import usePlayer from './usePlayer';
import useAnimationFrame from './useAnimationFrame';
import { lerp } from '../utils/lerp';
import useCatalog from './useCatalog';

function wait(ms:number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export default function useBook() {
	const page = useRef<HTMLDivElement>(null);

	const {
		initialPrompt,
		promptInsight,
		usernamePrompt,
		character: { character },
		tone: { tone },
		endConversationPrompt,
		isLoaded: isGlobalVarLoaded,
		videos,
		sounds,
	} = useGlobalVariables();

	const {
		playerId,
		DisplayName,
	} = usePlayer();

	const {
		items,
	} = useCatalog();

	const [canWrite, setCanWrite] = useState(Boolean(DisplayName));
	const [text, setText] = useState('');
	const [hasSent, setHasSent] = useState(false);
	const [showSketch, setShowSketch] = useState(false);
	const [isAskingName, setIsAskingName] = useState(false);

	const textRef = useRef<HTMLDivElement>(null);

	const introVideoRef = useRef<HTMLVideoElement>(null);
	const loopVideoRef = useRef<HTMLVideoElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const sketchRef = useRef<HTMLImageElement>(null);
	const dispatch = useAppDispatch();

	const variables = {
		prompt: text,
		tone,
		character,
		player: (DisplayName || '').split('#')[0],
		image: items.find(i => JSON.parse(i.data.Value as string || '{}').character === character)?.playfab.ItemImageUrl,
	};

	const getStatement = useCallback((prompt) => {
		if (!isGlobalVarLoaded) return '';

		let p = prompt;
		Object.entries(variables).forEach(([key, value]) => {
			p = p.replace(new RegExp(`{${key}}`, 'g'), value);
		});

		return p;
	}, [initialPrompt, variables, isGlobalVarLoaded]);

	const hide = useCallback((delayAfterMs = 0) => {
		return new Promise((resolve) => {
			const letters = [...textRef.current.querySelectorAll('.letter')] as HTMLSpanElement[];
			letters.sort(() => Math.random() - 0.5);

			const groups = letters.reduce((acc, letter, i) => {
				const groupIndex = Math.floor(i / 5);
				if (!acc[groupIndex]) acc[groupIndex] = [];
				acc[groupIndex].push(letter);
				return acc;
			}, [] as HTMLSpanElement[][]);
			
			setCanWrite(false);

			groups.forEach((g, i) => {
				g.forEach((l) => {
					l.style.setProperty('--delay', `${i * 0.025}s`);
					l.classList.add('hide');
				});
			});

			setTimeout(resolve, groups.length * 1000 * 0.025 + delayAfterMs);
		});
	}, [textRef.current]);

	function showPrompt() {
		setCanWrite(true);
		setTimeout(() => {
			textAreaRef.current.focus();
		}, 100);
		setText('');
	}

	function setAnswerText(txt:string) {
		setText(`\n\n${txt}`);
	}

	const onResult = useCallback(async (str:string) => {
		dispatch(writePlayerEvent({
			name: isAskingName ? 'player_received_username' : 'player_receive_completion',
			body: {
				completion: str,
			},
		}));

		if (isAskingName) {
			const { name } = JSON.parse(str) || {};
			dispatch(updateDisplayName(name + '#' + Math.floor(Math.random() * 1000).toString().padStart(3, '0')));
			setIsAskingName(false);
			await wait(1000);
		} else {
			await wait(Math.min(5000, str.split('').length * 200));
			await hide(1000);
		}

		showPrompt();
	}, [isAskingName]);

	const onProgress = useCallback((partial:string) => {
		if (isAskingName) return;
		setAnswerText(partial.replace(/"/g, ''));
	}, [isAskingName]);

	const {
		queryAI,
	} = useOpenAIChat({
		onResult,
		onProgress,
	});

	function writeText(txt:string) {
		const nodes = txt.split('');

		return new Promise<void>(async (resolve) => {
			let str = '';
			await nodes.map(async (word, i) => {
				await wait(75 * i);
				str += word;
				setAnswerText(str);
			});
			resolve();
		});
	}

	useEffect(() => {
		setHasSent(false);
	}, [character]);

	const onEnter = useCallback(async () => {
		if (!canWrite) return;

		await hide(1000);

		const prompt = text.trim().replace('\n', '');

		if (prompt.toLowerCase() === endConversationPrompt.toLowerCase()) {
			dispatch(writePlayerEvent({
				name: 'player_ended_conversation',
				body: {
					character,
				},
			}));

			await wait(1000);

			showPrompt();
		} else if (isAskingName) {
			dispatch(writePlayerEvent({
				name: 'player_entered_username',
				body: {
					prompt,
				},
			}));

			queryAI(usernamePrompt.replace('{prompt}', prompt));
		} else {
			const statement = hasSent ? getStatement(promptInsight) : getStatement(initialPrompt);
			dispatch(writePlayerEvent({
				name: 'player_sent_prompt',
				body: {
					prompt: statement,
				},
			}));
			queryAI(statement);
			setHasSent(true);
		}
	}, [hasSent, promptInsight, initialPrompt, text, isAskingName, character, endConversationPrompt]);

	const onEnded = useCallback(async () => {
		loopVideoRef.current.play();
		loopVideoRef.current.classList.add('show');
		setShowSketch(true);

		await wait(2000);

		if (playerId && !DisplayName) {
			writeText('What is your name?');

			await wait(4000);
			await hide(1000);
			
			setIsAskingName(true);
			showPrompt();
		} else {
			setCanWrite(true);
		}
	}, [playerId, DisplayName]);

	const targetMouse = useRef({ x:0.5, y: 0.5 });
	const currentMouse = useRef({ x:0.5, y: 0.5 });

	function onMouseMove(e:MouseEvent) {
		targetMouse.current.x = e.clientX / window.innerWidth;
		targetMouse.current.y = e.clientY / window.innerHeight;
	}

	useAnimationFrame(() => {
		currentMouse.current.x = lerp(currentMouse.current.x, targetMouse.current.x, 0.025);
		currentMouse.current.y = lerp(currentMouse.current.y, targetMouse.current.y, 0.025);

		page.current?.style.setProperty('--x', `${(currentMouse.current.x - 0.5) * -5}%`);
		page.current?.style.setProperty('--y', `${(currentMouse.current.y - 0.5) * -5}%`);
	});

	useEffect(() => {
		if (!loopVideoRef.current || !introVideoRef.current) return () => {};

		introVideoRef.current.addEventListener('ended', onEnded);
		page.current.addEventListener('mousemove', onMouseMove);
		
		return () => {
			introVideoRef.current?.removeEventListener?.('ended', onEnded);
			page.current?.removeEventListener?.('mousemove', onMouseMove);
		};
	}, [introVideoRef.current, loopVideoRef.current, onEnded]);

	return {
		page,
		introVideoRef,
		loopVideoRef,
		textRef,
		sketchRef,
		text,
		textAreaRef,
		canWrite,
		videos,
		image: variables.image,
		sounds,
		showSketch,
		isLoaded: isGlobalVarLoaded,
		
		wait,
		onEnter,
		setText,
	};
}