import { useEffect } from 'react';
import { ITEM_CLASSES } from '../Constants';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getGlobalVariables } from '../redux/globalVariables';
import usePlayer from './usePlayer';

export default function useGlobalVariables() {
	const dispatch = useAppDispatch();
	const { playerId } = usePlayer();
	const globalVariables = useAppSelector((state) => state.globalVariables);

	useEffect(() => {
		if (!globalVariables.isLoaded && playerId) {
			dispatch(getGlobalVariables());
		}
	}, [globalVariables.isLoaded, playerId]);

	const overrides = useAppSelector((state) => state.inventory.list.filter(i => i.playfab.ItemClass === ITEM_CLASSES.OVERRIDE)) as OverrideItem[];

	overrides.sort((a, b) => {
		return new Date(a.playfab.PurchaseDate).valueOf() - new Date(b.playfab.PurchaseDate).valueOf();
	});

	// Object.entries(overrides.reduce((carry, item) => {
	// 	if (!carry[item.data.GlobalVariableName]) carry[item.data.GlobalVariableName] = [];
	// 	carry[item.data.GlobalVariableName].push([item.data.Value, new Date(item.playfab.PurchaseDate)]);

	// 	return carry;
	// }, {})).forEach(([key, value]) => {
	// 	console.log(key);
	// 	console.table(value);
	// });

	if (overrides && globalVariables.isLoaded) {
		const copy = structuredClone(globalVariables);

		overrides.forEach(override => {
			if (!copy[override.data.GlobalVariableName] || typeof copy[override.data.GlobalVariableName] === 'string') {
				copy[override.data.GlobalVariableName] = override.data.Value;
			} else {
				copy[override.data.GlobalVariableName] = {
					...copy[override.data.GlobalVariableName],
					...override.data.Value,
				};
			}
		});

		return copy;
	}


	return globalVariables;
}