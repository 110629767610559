import { useState } from 'react';

type UseOpenAIProps = {
	onResult: (result: string) => void;
	onProgress?: (result: string, fragment: string) => void;
};

type Prompt = {
	content: string;
	role: string;
};

export default function useOpenAIChat({ onResult, onProgress }:UseOpenAIProps) {
	const [querying, setQuerying] = useState(false);
	const [error, setError] = useState<string>(null);
	const [isActive] = useState(true);

	const [apiKey] = useState<string>('431e4f8121bc4fad8a4162e8d7bb4bcf');
	const [messages, setMessages] = useState<Prompt[]>([]);

	function queryAI(prompt:string) {
		setQuerying(true);
		setError(null);

		const newMessages = [
			...messages,
			{
				content: prompt,
				role: 'user',
			},
		];
		setMessages(newMessages);


		const resultMessage:Prompt = {
			content: '',
			role: '',
		};

		const onFinished = () => {
			onResult(resultMessage.content);
			setMessages((oldMessages) => {
				return [
					...oldMessages,
					resultMessage,
				];
			});
			setQuerying(false);
		};

		const decoder = new TextDecoder('utf-8');
		fetch('https://openaixr.openai.azure.com/openai/deployments/ChatModel/chat/completions?api-version=2023-03-15-preview', {
			headers: {
				'Content-Type': 'application/json',
				// Authorization: `Bearer ${apiKey}`,
				'Api-Key': apiKey,
			},
			method: 'POST',
			body: JSON.stringify({
				messages: newMessages,
				model: 'gpt-3.5-turbo',
				temperature: 0.5,
				max_tokens: 2048,
				stream: true,
			}),
		}).then((response) => {
			const reader = response.body.getReader();
			return new ReadableStream({
				start(controller) {

					function pump() {
						return reader.read().then(({ done, value }) => {
							try {
								const chunk = decoder.decode(value);

								const lines = chunk.split('\n').filter(line => line.trim() !== '');

								// Process each line
								for (const line of lines) {
									
									// Remove the "data: " prefix from the line
									const message = line.replace(/^data: /, '');

									// console.log(message);
									if (message === '[DONE]') {
										onFinished();
										return;
									}

									const json = JSON.parse(message);
									const delta = json.choices[0]?.delta;

									if (delta.content) {
										resultMessage.content += delta.content;
										if (onProgress) onProgress(resultMessage.content, delta.content);
									}

									if (delta.role) {
										resultMessage.role = delta.role;
									}

								}

								// When no more data needs to be consumed, close the stream
								if (done) {
									controller.close();
									onFinished();
									return;
								}
								// Enqueue the next data chunk into our target stream
								controller.enqueue(value);
								pump();
							} catch (e) {
								// In case of error, close the stream
								controller.error(e);
								controller.close();
								onFinished();
							}
						});
					}

					return pump();

				},
			});
		}).then((stream) => {
			return new Response(stream);
		}).catch((err) => {
			setError('Error parsing the result from the AI. Please try again.');
			setQuerying(false);
			console.error(err);
		});


	}

	return {
		isActive,
		queryAI,
		querying,
		error,
		messages,
		reset: () => setMessages([]),
	};
}