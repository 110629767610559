import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getGlobalVariables = createDebouncedAsyncAction(
	'playfab-xr/getGlobalVariables',
	xrAction(() => {
		return getXrApi().Client.GetGlobalVariable();
	}),
);

const initialState = {
	initialPrompt: '',
	promptInsight: '',
	usernamePrompt: '',
	tone: { tone: '' },
	character: { character: '' },
	endConversationPrompt: '',
	isLoaded: false,
	videos: null as { intro: string, loop: string },
	sounds: null as { ambiance: string },
};

const globalVariables = createSlice({
	name: 'globalVariables',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				...action.payload.data.GlobalVariables.reduce((acc, curr) => {
					acc[curr.dataKey] = curr.dataVal;
					return acc;
				}, {} as Record<string, any>),
			};
		});
	},
	initialState,
});

export default globalVariables;
