
// @ts-check
import sha1 from 'sha1';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAppDispatch } from '../config/store';
import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';

/**
	Creates a thunk action that cannot be called with the same parameters before the previous identical one is resolved
	Returns the redux action, with reducers as property, that can be added to extraReducers of slice creator.
*/
export default function createDebouncedAsyncAction<ActionType = any, ThunkArgs = any>(
	name:string, payloadCreator: AsyncThunkPayloadCreator<ActionType, ThunkArgs>,
) {
	const processing:{ [key:string]: Promise<any> | null } = {};
	const thunk = createAsyncThunk(
		name,
		payloadCreator,
	);
	
	const debounced = (data = {} as ThunkArgs) => {
		return (dispatch:IAppDispatch) => {
			const hash = sha1(JSON.stringify(data || {}));
			if (Boolean(processing[hash])) {
				return processing[hash];
			}
			
			processing[hash] = dispatch(thunk(data)).then(r => {
				processing[hash] = null;
				return r;
			}).catch(err => {
				processing[hash] = null;
				throw err;
			});
			return processing[hash];
		};
	};

	debounced.actionName = name;
	debounced.actions = thunk;

	return debounced;
}