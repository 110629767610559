import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

const defaultMissionState = {
	list: [] as IXRMissionItem[],
};
type IMissionState = typeof defaultMissionState;

export const getMissionInventory = createDebouncedAsyncAction(
	'playfab-xr/getMissionInventory',
	xrAction(getXrApi().Client.GetMissionInventory),
);

export const resetMission = createDebouncedAsyncAction(
	'playfab-xr/resetMission',
	xrAction((ItemId:string) => {
		return getXrApi().Client.ResetMission({
			ItemId,
		});
	}),
);

const missions = createSlice({
	name: 'missions',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getMissionInventory.actions.fulfilled, (state:IMissionState, action) => {
			return {
				...state,
				list: [...action.payload.data.missions.PlayerMissions.map(mission => {
					mission.data = { ...mission.publicData };

					mission.objectives = mission.objectives.map(objective => {
						return {
							...objective,
							data: objective.data.reduce((c, data) => {
								c[data.dataKey] = data.dataVal;
								return c;
							}, {}),
						};
					});
					return mission;
				})],
			};
		});
		builder.addCase(resetMission.actions.fulfilled, () => {});
	},
	initialState: defaultMissionState,
});

export default missions;