import React, { memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import useBook from '../../hooks/useBook';
import ImageWithVideoMask from '../ui/ImageWithVideoMask';
import AudioPlayer from '../ui/AudioPlayer';

function SpawningLetter({ letter }:{ letter:string }) {
	const ref = useRef<HTMLSpanElement>(null);

	return (
		<CSSTransition nodeRef={ref} in={true} appear timeout={600} mountOnEnter unmountOnExit>
			<span className="letter" ref={ref}>{letter}</span>
		</CSSTransition>
	);
}

function Home() {
	const {
		page,
		introVideoRef,
		loopVideoRef,
		textRef,
		text,
		videos,
		sketchRef,
		textAreaRef,
		canWrite,
		isLoaded,
		image,
		sounds,
		showSketch,
		
		onEnter,
		setText,
	} = useBook();
	
	if (!isLoaded) return null;

	return (
		<>
			<CSSTransition nodeRef={page} key="home" timeout={1200} in={true} appear>
				<div className="page home" ref={page}>
					<video className="book intro" ref={introVideoRef} src={videos.intro} autoPlay muted playsInline></video>
					<video className="book loop" ref={loopVideoRef} src={videos.loop} muted playsInline loop></video>
					<div className="text" ref={textRef}>{text.split('').map((l, i) => <SpawningLetter key={l + i} letter={l} />)}</div>
					<textarea ref={textAreaRef} onKeyUp={e => e.key === 'Enter' && onEnter()} value={text} placeholder="" disabled={!canWrite} onChange={e => setText(e.currentTarget.value)}></textarea>
					<div ref={sketchRef} className="sketch">
						<ImageWithVideoMask imageSrc={image} maskSrc={videos.mask} active={showSketch} />
					</div>
					<div className="grid"></div>
				</div>
			</CSSTransition>
			<AudioPlayer file={sounds?.ambiance} autoPlay loop />
		</>
	);
}

export default memo(Home);
