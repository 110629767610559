import React, { useEffect, useRef, useState } from 'react';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { useAppSelector } from '../../redux/config/store';

enum DrawingStates {
	Idle = 'Idle',
	AnimIn = 'AnimIn',
	AnimOut = 'AnimOut',
}

export default function ImageWithVideoMask({ maskSrc, imageSrc, active = false }:{ maskSrc:string, imageSrc:string, active?: boolean }) {
	const mask = useRef<HTMLVideoElement>(null);
	const image = useRef<HTMLImageElement>(null);
	const canvas = useRef<HTMLCanvasElement>(null);

	const hasLoadedInventory = useAppSelector(state => state.inventory.hasLoaded);

	const [previousSrc, setPreviousSrc] = useState(null);
	const alpha = useRef(1);
	const [drawingState, setDrawingState] = useState<DrawingStates>(DrawingStates.Idle);

	useEffect(() => {
		if (imageSrc === previousSrc || !hasLoadedInventory) return;

		if (imageSrc && previousSrc) {
			setDrawingState(DrawingStates.AnimOut);
		} else if (imageSrc && !previousSrc) {
			setDrawingState(DrawingStates.AnimIn);
			setPreviousSrc(imageSrc);
		}
	}, [imageSrc, previousSrc, hasLoadedInventory]);

	useAnimationFrame(({ delta }) => {
		if (!canvas || !image.current || !previousSrc) return;
		
		const ctx = canvas?.current?.getContext('2d');
		if (drawingState === DrawingStates.AnimIn) {
			const w = canvas.current.width  = mask.current.videoWidth;
			const h = canvas.current.height = mask.current.videoHeight;
	
			ctx.globalAlpha = 1;
			ctx.clearRect(0, 0, w, h);
			ctx.filter = 'url(#lumToAlpha)';
			ctx.drawImage( mask.current, 0, 0, w, h );
			ctx.filter = 'none';
			ctx.globalCompositeOperation = 'source-in';
			ctx.fillStyle = 'white';
			ctx.fillRect(0, 0, w, h);
			ctx.drawImage( image.current, w * 0.1, h * 0.1, w * 0.8, h * 0.8 );
			ctx.globalCompositeOperation = 'source-over';
		} else if (drawingState === DrawingStates.AnimOut) {
			alpha.current -= delta * 2;

			const w = canvas.current.width  = mask.current.videoWidth;
			const h = canvas.current.height = mask.current.videoHeight;
	
			ctx.clearRect(0, 0, w, h);
			ctx.globalAlpha = alpha.current;
			ctx.drawImage( image.current, w * 0.1, h * 0.1, w * 0.8, h * 0.8 );
 
			if (alpha.current <= 0.001) {
				alpha.current = 1;
				setDrawingState(DrawingStates.Idle);
				setPreviousSrc(null);
			}
		}
	});

	useEffect(() => {
		if (!mask.current || drawingState !== DrawingStates.AnimIn) return () => {};

		function onEnded() {
			setDrawingState(DrawingStates.Idle);
		}

		mask.current.currentTime = 0;
		mask.current.play();
		mask.current.addEventListener('ended', onEnded);

		return () => {
			mask?.current?.removeEventListener('ended', onEnded);
		};
	}, [mask.current, imageSrc, drawingState]);

	return active && (
		<div className="video-with-mask">
			<img className="image" ref={image} src={previousSrc} alt="" />
			<video className="video" ref={mask} src={maskSrc} muted playsInline></video>
			<canvas className="canvas" ref={canvas}></canvas>
			<svg width="0" height="0">
				<filter id="lumToAlpha">
					<feColorMatrix type="luminanceToAlpha" />
				</filter>
			</svg>
		</div>
	);
}